<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'app',
    components: {

    }
  }
</script>

<style>
  body,
  p,
  li {
    margin: 0;
  }

  input {
    outline: none;
    border: none;
    background-color: unset;
  }
</style>
