import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index";

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    // redirect: '/HjsjIndex1',
    component: resolve => require(['@/views/Home'], resolve),
    children: [
      // 在线检测
      // 环境数据
      {
        path: '/HjsjIndex1',
        name: 'HjsjIndex1',
        component: resolve => require(['@/views/ZXJC/HJSJ/index1'], resolve)
      },
      {
        path: '/HjsjIndex2',
        name: 'HjsjIndex2',
        component: resolve => require(['@/views/ZXJC/HJSJ/index2'], resolve)
      },
      {
        path: '/XmglIndex',
        name: 'XmglIndex',
        component: resolve => require(['@/views/XMGL/index'], resolve)
      },
      // 设备管理
      // 设备信息管理
      {
        path: '/SbglSbxxgl',
        name: 'SbglSbxxgl',
        component: resolve => require(['@/views/SBGL/sbxxgl'], resolve)
      },
      // 新增设备管理
      {
        path: '/SbglXzsbgl',
        name: 'SbglXzsbgl',
        component: resolve => require(['@/views/SBGL/xzsbgl'], resolve)
      },
      // 编辑安装信息
      {
        path: '/SbglBjazxx',
        name: 'SbglBjazxx',
        component: resolve => require(['@/views/SBGL/bjazxx'], resolve)
      },
      // 编辑安装工艺
      {
        path: '/SbglBjazgy',
        name: 'SbglBjazgy',
        component: resolve => require(['@/views/SBGL/bjazgy'], resolve)
      },
      // 数据导出日志
      {
        path: '/SbglSjdcrz',
        name: 'SbglSjdcrz',
        component: resolve => require(['@/views/SBGL/sjdcrz'], resolve)
      },
      // 管理账号
      {
        path: '/gsgly',
        name: 'gsgly',
        component: resolve => require(['@/views/ZHGL/gsgly'], resolve)
      },
      // 施工人员账号
      {
        path: '/sgryzh',
        name: 'sgryzh',
        component: resolve => require(['@/views/ZHGL/sgryzh'], resolve)
      },
      // 客户管理员
      {
        path: '/khgly',
        name: 'khgly',
        component: resolve => require(['@/views/ZHGL/khgly'], resolve)
      },
	  // 告警管理
	  // 告警信息
	  {
	    path: '/gjxx',
	    name: 'gjxx',
	    component: resolve => require(['@/views/GJGL/gjxx'], resolve)
	  },
    // 告警详情
    {
      path: '/gjxx1',
      name: 'gjxx1',
      component: resolve => require(['@/views/GJGL/gjxx1'], resolve)
    },
    // 告警设置
    {
      path: '/gjsz',
      name: 'gjsz',
      component: resolve => require(['@/views/GJGL/gjsz'], resolve)
    },

    // 告警设置-1
    {
      path: '/gjsz_1',
      name: 'gjsz_1',
      component: resolve => require(['@/views/GJGL/gjsz_1'], resolve)
    },
     // 告警设置-2
    {
      path: '/gjsz_2',
      name: 'gjsz_2',
      component: resolve => require(['@/views/GJGL/gjsz_2'], resolve)
    },
    // 告警设置-3
    {
      path: '/gjsz_3',
      name: 'gjsz_3',
      component: resolve => require(['@/views/GJGL/gjsz_3'], resolve)
    },
    // 告警设置-4
    {
      path: '/gjsz_4',
      name: 'gjsz_4',
      component: resolve => require(['@/views/GJGL/gjsz_4'], resolve)
    },
    // 告警设置-5
    {
      path: '/gjsz_5',
      name: 'gjsz_5',
      component: resolve => require(['@/views/GJGL/gjsz_5'], resolve)
    },
    // 施工管理
    {
      path: '/sggl',
      name: 'sggl',
      component: resolve => require(['@/views/SGGL/sggl'], resolve)
    },
    // 施工管理
    {
      path: '/sggl_1',
      name: 'sggl_1',
      component: resolve => require(['@/views/SGGL/sggl_1'], resolve)
    },
    // 施工管理
    {
      path: '/sggl_2',
      name: 'sggl_2',
      component: resolve => require(['@/views/SGGL/sggl_2'], resolve)
    },
    // 施工管理
    {
      path: '/rkgl',
      name: 'rkgl',
      component: resolve => require(['@/views/RKGL/rkgl'], resolve)
    },
    // 施工管理
    {
      path: '/glkh',
      name: 'glkh',
      component: resolve => require(['@/views/ZHGL/glkh'], resolve)
    },
    {
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
    },
    // 地图
    {
      path: '/dt',
      name: 'dt',
      component: resolve => require(['@/views/dt'], resolve)
    },
    {
      path: '/selectPoint',
      name: 'selectPoint',
      component: resolve => require(['@/views/selectPoint'], resolve)
    },

    ]
  },

  {
    path: '/login',
    name: 'login',
    component: resolve => require(['@/views/login'], resolve)
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {

	if (to.path === '/login') {
		next();
	} else {
		var token = sessionStorage.getItem('token')
		console.log(sessionStorage.getItem('token'), '这')
		if (token == null || token == '') {
			next('/login');
		} else {
			next();
		}
	}
});

export default router
