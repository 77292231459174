import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    //监管用户
    //修改管理员信息
    userData: {},
    //企业列表的一堆东西
    businessManagementCurrentPageValue: '',
    businessManagementBaseData:{},
    isCompanyLoginPoints:false,//企业端登录  --- 多个经营点
  },
  mutations: {
    set_token(state, str) {
      state.token = str
      sessionStorage.setItem("token",str);
    },
    set_userData(state, obj) {
      state.userData = obj
    },
    businessManagementCurrentPageValue(state, num) {
      state.businessManagementCurrentPageValue = num;
    },
    businessManagementBaseData(state, obj) {
      state.businessManagementBaseData = obj;
    },
    isCompanyLoginPoints(state, bool) {
      state.isCompanyLoginPoints = bool;
    },
  },
  actions: {
  },
  modules: {
  }
})
