import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import 'lib-flexible'
import './utils/url'
import {myPost,myGet, myHttp, newPost, newGet, newDelete,getReportGet} from './utils/http'
import axios from "axios";
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.prototype.$newPost = newPost
Vue.prototype.$newGet = newGet
Vue.prototype.$newDelete = newDelete
Vue.prototype.$post = myPost
Vue.prototype.$get = myGet
Vue.prototype.$ajax = myHttp
Vue.prototype.$http = axios
Vue.prototype.$getReportGet = getReportGet

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export function changeIconTitle() {
	const changeFavicon = link => {
		let $favicon = document.querySelector('link[rel="icon"]');
		if ($favicon !== null) {
			$favicon.href = link;
		} else {
			$favicon = document.createElement("link");
			$favicon.rel = "icon";
			$favicon.href = link;
			document.head.appendChild($favicon);
		}
	};
	let iconUrl = "@/assets/dyicon.ico"; // 图片地址
	changeFavicon(iconUrl); // 动态修改网站图标
	let title = "大业电气"; // 网站标题
	document.title = title; // 动态修改网站标题
}